/* 4.1 - TOPBAR */
.topbar{
	/*background-color: $bg__topbar;*/
	padding: 0 0;
	height: 40px;

	.topbar-left{
		.welcome-text{
			font-style: italic;
			color: $white;
		    padding: 8px 0;
		}
	}
	.topbar-right{
		text-align: right;
		.topbar-menu{
			display: inline-table;
			margin-bottom: 0;
			vertical-align: top;
			li{
				list-style: none;
				float: left;
				color: $white;
				padding: 0 10px;
				font-size: 12px;
				a{
					color: $font__topbar-nav-color;
					&:hover{
						color: $font__topbar-nav-hover-color;
					}
				}
			}
		}
		.topbar-sosmed{
			display: inline-table;
			margin-bottom: 0;
			li{
				list-style: none;
				float: left;
				font-size: 16px;
				padding: 0 10px;
				a{
					color: $font__topbar-nav-color;
					&:hover{
						color: $font__topbar-nav-hover-color;
					}
				}
			}
		}
	}
	&:after{
		@extend .cfix;
	}
}

.topbar-logo{
	background-color: $white;
    padding: 5px 0;
	
}

.contact-info{
	float: right;
    margin-top: 15px;
	> div{
		display: inline-table;
		margin-right: 40px;
	}
}
.navbar-toggle .icon-bar {
     background: $color_1;
}
.navbar-toggle{
	float: left;
}

/* 4.2 - NAVBAR */

.navbar-main {
  background-color: transparent;
  border-radius: 0;
  margin-bottom: 0;
  border: 0;
  position: relative;
  z-index: 3;
   -webkit-transition: padding .2s ease-in-out;
  transition: padding .2s ease-in-out;
  > .container{
  	position: relative;
    padding: 10px 15px;
  }
  .navbar-brand{
    img{
      &:last-child{
        display: none;
      }
    }
  }
  &.stiky {
    background-color: $white;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    padding-top: 40px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; 
    bottom: initial;
    .navbar-brand{
      img{
        display: none;
        &:last-child{
          display: block;
        }
      }
    }
  }

  .navbar-collapse{
  	padding-right: 0;
    padding-left: 0;
  }
  ol, li{
  	margin: 0 0;
	font-size: 12px;
	font-weight: 700;
  }
  .nav > li > a {
	color: $font__navbar-color;
  }
  .nav > li.active > a,
  .nav > li > a:hover,
  .nav > li > a:focus {
    color: $font__navbar-active-color;
    background-color: transparent;
  }  
  .nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    background-color: transparent;
    border-color: transparent;
    color: $font__navbar-hover-color;
  }
  .caret {
    margin-left: 10px;
  }
  .nav.navbar-right > li{
      margin-right: 15px;
  }
  .fa{
  	font-size: 18px;
  }
  .dropdown-menu{
    background-color: $color_2;
    min-width: 180px;
    color: $white;
    padding: 0;
    border: 0;
    border-radius: 0;
    > li{
    	text-transform: uppercase;
    	/*border-bottom: 1px solid lighten($color_1, 10%);*/
    	a{
    		padding: 10px 20px;
		    color: $white;
		    &:hover{
	    	    background-color: $color_2;
	    	    color: $font__topbar-nav-hover-color;
		    }
    	}
    }
  }


}

.header-1{
  position: fixed;
  z-index: 999;
  width: 100%;
  /*overflow: hidden;*/
  .navbar-nav{
    background-color: $font__topbar-nav-hover-color;
    position: relative;

    &:after{
      content: '';
      position: absolute;
      right: -100%;
      width: 100%;
      height: 100%;
      display: block;
      background-color: $font__topbar-nav-hover-color;
    }
  }
  .stiky {
    .navbar-nav{
      background-color: $white;
      &:after{
        content: none;
      }
    }
    .nav > li.active > a{
      color: $font__topbar-nav-hover-color;
    } 
    .nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
      color: $color_1;
    }
  }
}

.social-icon{

  li{
    list-style: none;
    float: left;
    margin: 0 2px;
    text-align: center;
    a{
      display: block;
      width: 35px;
      height: 35px;
      background-color: transparent;
      padding-top: 2px;
    }
  }
}